import React from "react"
import theme from "@styles/theme"
import { Grid } from "semantic-ui-react"
import { H2, H5, Paragraph1, Paragraph2 } from "@styles/Global.styles"
import { PricingImageCardContainerProps, PricingImageCardItem } from "@models/PricingImageCard/PricingImageCard.models"
import KpButton from "@elements/KpButton/KpButton"
import { PricingImageCardHeader, PricingImageCardHeaderContainer, PricingImageCardItemContainer, PricingImageCardItemContainerImage, PricingImageCardItemContainerText, PricingImageCardPrice, PricingImageCardText, PricingImageColumn } from "./PricingImageCard.styles"

const PricingImageCardContainer = ({ heading, subHeading, pricingImageCardItems, headingColor, subHeadingColor } : PricingImageCardContainerProps) => {

  return (
            <div>
                <PricingImageCardHeaderContainer>
                    <H2 color={headingColor ? headingColor : theme.brand.colors.white}>{heading}</H2>
                    {
                        subHeading &&
                        <Paragraph1 color={subHeadingColor ? subHeadingColor : theme.brand.colors.white}>{subHeading}</Paragraph1>
                    }
                </PricingImageCardHeaderContainer>
                <Grid centered>
                {
                    pricingImageCardItems.map((pricingCardItem: PricingImageCardItem) => {
                        return (
                            <PricingImageColumn mobile={16} tablet={10} computer={10} largeScreen={10}>
                                <PricingImageCardItemContainer>
                                    <div>
                                        <PricingImageCardItemContainerImage src={pricingCardItem.image.url} />
                                    </div>
                                    <PricingImageCardItemContainerText>
                                        <div>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={12}>
                                                        <PricingImageCardHeader>{pricingCardItem.heading}</PricingImageCardHeader>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={4} computer={4}>
                                                    {
                                                        pricingCardItem.price &&
                                                        <Grid.Column textAlign='right' width={4}>
                                                            <PricingImageCardPrice color={theme.brand.colors.green}>
                                                                <label>
                                                                    From
                                                                </label>
                                                                <span>
                                                                    ${pricingCardItem.price}
                                                                </span>
                                                            </PricingImageCardPrice>
                                                        </Grid.Column>
                                                    }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid.Column textAlign='left' width={16}>
                                                <PricingImageCardText>{pricingCardItem.body}</PricingImageCardText>
                                            </Grid.Column>
                                        </div>
                                        <div>
                                        {
                                            pricingCardItem.button &&
                                            <KpButton 
                                                id='GiftingImageInfoBannerButton' 
                                                buttonType={pricingCardItem.button.type} 
                                                color={pricingCardItem.button.theme} 
                                                link={pricingCardItem.button.url}>
                                                {pricingCardItem.button.text}
                                            </KpButton>
                                        }
                                        </div>
                                    </PricingImageCardItemContainerText>
                                </PricingImageCardItemContainer>
                            </PricingImageColumn>
                        )
                    })
                }
                </Grid>
            </div>
  )
}

export default PricingImageCardContainer