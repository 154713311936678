import { convertKontentColorToHex } from "@utils/Helpers";
import { string } from "yup";
import { PlateImagesBlockProps } from "./PlateImagesBlock"

interface PlateImageBlockKontentData {
    plate_images:{
        value:[
            {
                url: string
                description: string | null,
                width: number
                height: number
            }
        ]
    },
    title: {
        value: string
    },
    background_colors:{
        value:[
            {
                codename: string
            }
        ]
    }
}

export const mapToPlateImagesBlock = (data: PlateImageBlockKontentData)=>{
    const {plate_images, title, background_colors} = data;

    const result: PlateImagesBlockProps = {
        title: title.value,
        backgroundColor: convertKontentColorToHex(background_colors.value[0].codename),
        images: plate_images.value
    }
    return result;
}
