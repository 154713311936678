import { PricingImageCardContainerProps, PricingImageCardItem } from "@models/PricingImageCard/PricingImageCard.models";
import {convertKontentColorToHex} from '@utils/Helpers'
import { mapToButton } from "@utils/mapper";

export const mapToPricingImageCardContainer = (data:any): PricingImageCardContainerProps =>{
    return {
        backgroundColour: convertKontentColorToHex(data.background_colors.value[0].codename),
        heading: data.heading.value,
        subHeading: data.sub_heading.value,
        pricingImageCardItems: mapToPricingImageCardItem(data.pricing_image_cards.linked_items)
    }
}

export const mapToPricingImageCardItem = (data:any): PricingImageCardItem[] =>{
    return data.map((data: any) => {
        const pricingImageCardItem = data.elements;

        return {
            heading: pricingImageCardItem.heading.value,
            body: pricingImageCardItem.body.value,
            button: mapToButton(pricingImageCardItem),
            image: {
                url: pricingImageCardItem.image.value[0].url,
                description: pricingImageCardItem.image.value[0].description
            },
            price: pricingImageCardItem.price.value
        }
    });
}