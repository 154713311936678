import { mapToButton } from "@utils/mapper"
import { StyledCombinationFinderProps } from "./StyledQuickCombinationFinder"

export const mapToStyledCombinationFinder = (
  data: any
): StyledCombinationFinderProps => {
  const { elements } = data.combination_search.linked_items[0]
  const hideVehicleOptions =
    elements.only_for_car.value.length > 0 &&
    elements.only_for_car.value[0].codename === "car_only"
  const plateImage = elements.plate_background_image.value[0]
  return {
    title: elements.title.value,
    subtitle: elements.description.value,
    button: mapToButton(elements),
    hideVehicleOptions: hideVehicleOptions,
    plateBackgroundImage: plateImage.url,
    plateImageWidth: plateImage.width,
    plateImageHeight: plateImage.height,
    plateHeaderRatio: elements.combination_top_margin_ratio.value,
    apiUrl: "",
  }
}
