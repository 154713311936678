import { PlateSizeGuideBannerProps, PlateSizeGuidePlate } from "@models/PlateSizeGuideBanner/PlateSizeGuideBanner.models";
import { PlateSizeGuideModel } from "@models/ProductModels/PlateHoleGuideModel";
import { mapToButton } from "@utils/mapper";

export const mapToPlateSizeGuideBanner = (data: any): PlateSizeGuideBannerProps => {
    const display_image_in_ratio = data.display_plate_image_size_in_ratio.value;
    return {
        heading: data.heading.value,
        subHeading: data.sub_heading.value,
        plates: mapToPlateSizeGuidePlate(data),
        button: mapToButton(data),
        displayImageInRatio: display_image_in_ratio.length > 0 ? (display_image_in_ratio[0].codename == 'true' ? true : false) : true
    }
}

export const mapToPlateSizeGuidePlate = (data: any): PlateSizeGuidePlate[] => {
    return data.plates.linked_items.map((data: any) => {
        const plate = data.elements;
        return {
            heading: plate.title.value,
            width: plate.size_width.value,
            height: plate.size_height.value,
            image: {
                description: plate.plate_image.value[0].description,
                url: plate.plate_image.value[0].url
            }
        }
    })
}