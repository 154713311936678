import React from 'react'
import styled from "@emotion/styled";
import mq from '@styles/mq'
import theme from '@styles/theme';

export const PlateSizeGuideBannerImagesContainer = styled.div`
    display: flex;
    ${mq({
        flexDirection: ['column', 'row', 'row'],
        marginTop: ['0','30px','30px'],
    })}
    justify-content: space-between;
    width: 100%;
`

export const PlateSizeGuideBannerImageContainer = styled.div`
    text-align: center;
`

export const PlateSizeGuideBannerItemContainer = styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 25px;
    flex: 1;
`

export const PlateSizeGuideBannerImage  = styled(({width, ...others})=><img {...others}></img>)`
    width: ${props => props.width}%;
`

export const PlateSizeGuideBannerImageHeading = styled.span`
    ${mq({
        fontSize: ['20px', '20px', '20px']
    })}
    font-weight: bold;
`

export const PlateSizeGuideBannerImageSize = styled.span`
    ${mq({
        fontSize: ['14px', '14px', '14px']
    })}
    line-height: 2;
    color: ${theme.brand.colors.darkGrey};
    float: right;
`