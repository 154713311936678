import React from "react"
import styled from "@emotion/styled"
import mq from "@styles/mq"
import theme from "@styles/theme"
import { GridColumn } from "semantic-ui-react"

export const FluidImageGridBannerContainer = styled.div`
  overflow: hidden;
  position: relative;
  ${mq({
    width: ["auto", "100%", "100%"],
    height: ["auto", "63vw", "51vw"],
    maxHeight: ["auto", "644px", "890px"],
  })}
  > div:first-child {
    left: 50%;
    justify-content: center;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 130%;
  }
`

export const FluidImageGridBannerContainerMobile = styled.div`
  > div:first-child {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
`

export const FluidImageGridBannerImageContainer = styled(
  ({ alignment, padding, flex, ...others }) => <div {...others}></div>
)`
  display: flex;
  flex-direction: ${props =>
    props.alignment === "horizontal" ? "row" : "column"};
  flex: ${props => props.flex};
  padding: ${props => props.padding};
`

export const FluidImageBannerSquareImage = styled(({ margin, ...others }) => (
  <img {...others}></img>
))`
  width: 100%;
  height: 100%;
  vertical-align: top;
  margin: ${props => props.margin};
  border-radius: 6px;
`
export const FluidImageBannerHorizontalImage = styled(
  ({ margin, ...others }) => <img {...others}></img>
)`
  width: 100%;
  height: 100%;
  vertical-align: top;
  margin: ${props => props.margin};
  border-radius: 6px;
`

export const FluidImageBannerVerticalImage = styled(({ margin, ...others }) => (
  <img {...others}></img>
))`
  width: 100%;
  height: 100%;
  vertical-align: top;
  margin: ${props => props.margin};
  border-radius: 6px;
`
