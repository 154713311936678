import { ImageGridContainerProps, ImageGridItemProps } from "@models/ImageGrid/ImageGrid.models";
import {convertKontentColorToHex} from '@utils/Helpers'

export const mapToImageGridContainer = (data:any): ImageGridContainerProps =>{
    return {
        backgroundColour: convertKontentColorToHex(data.background_colors.value[0].codename),
        heading: data.heading.value,
        subHeading: data.sub_heading.value,
        imageGridItems: mapToImageGridItem(data.image_grid_items.linked_items)
    }
}

export const mapToImageGridItem = (data:any): ImageGridItemProps[] =>{
    return data.map((data: any) => {
        const imageGridItem = data.elements;

        return {
            heading: imageGridItem.heading.value,
            subHeading: imageGridItem.body.value,
            url: imageGridItem.url.value,
            image: {
                url: imageGridItem.image.value[0].url,
                description: imageGridItem.image.value[0].description
            }
        }
    });
}