import React, { Component } from 'react'
import { PlateSizeGuideModel } from '@models/ProductModels/PlateHoleGuideModel'
import theme from '@styles/theme';
import { PlateSizeGuideBannerProps } from '@models/PlateSizeGuideBanner/PlateSizeGuideBanner.models';
import { H1, H2, Paragraph1 } from '@styles/Global.styles';
import KpButton from '@elements/KpButton/KpButton';
import { PlateSizeGuideBannerImage, PlateSizeGuideBannerImageContainer, PlateSizeGuideBannerImageHeading, PlateSizeGuideBannerImagesContainer, PlateSizeGuideBannerImageSize, PlateSizeGuideBannerItemContainer } from './PlateSizeGuideBanner.styles';
import { Container, Grid } from 'semantic-ui-react';

class PlateSizeGuideBanner extends Component<PlateSizeGuideBannerProps> {
    render = () => {
        const { heading, subHeading, plates, button, displayImageInRatio } = this.props;
        const maxWidth = Math.max.apply(Math, plates.map((plate) => parseInt(plate.width)));
        return <Grid>
            <Grid.Row centered>
                <Grid.Column mobile={16} tablet={10} computer={7} textAlign='center'>
                    <H2>{heading}</H2>
                    <Paragraph1 color={theme.brand.colors.darkGrey}>{subHeading}</Paragraph1>
                    <KpButton fullWidth='mobile' id='plate_size_guide_banner_button' color={button.theme} buttonType={button.type} link={button.url}>{button.text}</KpButton>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <PlateSizeGuideBannerImagesContainer>
                    {
                        plates.map((plate) => {
                            const imageWidth = displayImageInRatio ? parseInt(plate.width) === maxWidth ? 100 : (parseInt(plate.width) / maxWidth) * 100 : 100;
                            return <PlateSizeGuideBannerItemContainer>
                                <div>
                                    <PlateSizeGuideBannerImageHeading>
                                        {plate.heading}
                                    </PlateSizeGuideBannerImageHeading>
                                    <PlateSizeGuideBannerImageSize>
                                        {`${plate.width}mm x ${plate.height}mm`}
                                    </PlateSizeGuideBannerImageSize>
                                </div>
                                <PlateSizeGuideBannerImageContainer>
                                    <PlateSizeGuideBannerImage width={imageWidth} src={plate.image.url} alt={plate.image.description} />
                                </PlateSizeGuideBannerImageContainer>
                            </PlateSizeGuideBannerItemContainer>
                        })
                    }
                </PlateSizeGuideBannerImagesContainer>
            </Grid.Row>
        </Grid>
    }
}

export default PlateSizeGuideBanner