import styled from "@emotion/styled";
import theme from "@styles/theme";
import mq from '@styles/mq';
import { GridColumn } from "semantic-ui-react";
import { H5, Paragraph1, Paragraph2 } from "@styles/Global.styles";

export const PricingImageCardItemContainer = styled.div`
    background-color: ${theme.brand.colors.white};
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export const PricingImageCardItemContainerImage = styled.img`
    width: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
`

export const PricingImageColumn = styled(GridColumn)`
    max-width: 545px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 1rem;
    margin-right: 1rem;
`
export const PricingImageCardItemContainerText = styled.div`
    padding: 20px 30px 30px 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    div:first-child     {
        flex: 1
    }
`

export const PricingImageCardPrice = styled.div`
    &:first-of-type {
        margin-right: 15px;
    }

    > span {
        color: ${props => props.color};
        ${mq({
            fontSize: ['14px', '18px', '18px']
        })}
        line-height: 28px;
        margin-left: 3px;
        font-weight: bold;
    }    

    > label {
        color: #B7B7B7;
        font-weight: 100;
        font-size: 12px;
        letter-spacing: -0.26px;
        vertical-align: top;
        line-height: 2;
        display: inline-block;
    }

    display: inline-block;
`

export const PricingImageCardHeader = styled(H5)`
    ${mq({
        marginBottom: ['0px !important', '10px !important', '10px !important']
    })}
`

export const PricingImageCardText = styled(Paragraph2)`
    margin-bottom: 10px !important;
    color: ${theme.brand.colors.darkGrey};
`

export const PricingImageCardHeaderContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
`