import { FluidImageGridBannerProps, FluidImageGridImage } from "@models/FluidImageGridBanner/FluidImageGridBanner.model"
import { url } from "inspector";

export const mapToFluidImageGridBannerContainer = (data: any): FluidImageGridBannerProps => {
    const images = [];

    for (const property in data) {
        images.push({
            url: data[property].value[0].url,
            description: data[property].value[0].description
        })
      }

    return {
        images: images
    }
}