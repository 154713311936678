import { H2 } from '@styles/Global.styles';
import theme from '@styles/theme';
import React from 'react'
import { Grid, Image } from 'semantic-ui-react';
import { ImageBlockHeaderSection } from './PlateImagesBlock.styles';

export interface PlateImagesBlockProps {
    title: string
    images: [
        {
            url: string
            description: string | null
        }
    ]
    backgroundColor: string|string[]
}
interface State {

}

class PlateImagesBlock extends React.Component<PlateImagesBlockProps, State>{
    constructor(props: PlateImagesBlockProps) {
        super(props);
    }
    render() {
        const { title, images } = this.props;
        const titleColor = this.props.backgroundColor.toString().toUpperCase() === '#FFFFFF' ? theme.brand.colors.black : theme.brand.colors.white;
        return (
            <div>
                <ImageBlockHeaderSection>
                    <H2 color={titleColor}>{title}</H2>
                </ImageBlockHeaderSection>
                <Grid columns='15' centered>
                    {
                        images.map((item) => {
                            return (
                                <Grid.Column textAlign='left' mobile={16} tablet={5} computer={5}>
                                    <Image src={item.url} alt={`landing page image: ${item.description}`}/>
                                </Grid.Column>
                            )
                        })
                    }
                </Grid>
            </div>
        )
    }
}

export default PlateImagesBlock;