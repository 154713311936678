import { FluidImageGridBannerProps } from "@models/FluidImageGridBanner/FluidImageGridBanner.model";
import React from "react";
import { Grid, Responsive } from "semantic-ui-react";
import { FluidImageBannerHorizontalImage, FluidImageBannerSquareImage, FluidImageBannerVerticalImage, FluidImageGridBannerContainer, FluidImageGridBannerContainerMobile, FluidImageGridBannerImageContainer } from "./FluidImageGridBanner.styles";
import theme from "@styles/theme";

class FluidImageGridBanner extends React.Component<FluidImageGridBannerProps>{
    constructor(props: FluidImageGridBannerProps) {
        super(props)
    }

    renderDesktopLayout = (images: any) => {
        return (
            <div>
                <FluidImageGridBannerImageContainer alignment='vertical' padding='0 15px 0 0'>
                    <FluidImageGridBannerImageContainer padding='0 0 15px 0'>
                        <FluidImageBannerSquareImage src={images[0].url} alt={images[0].description} />
                    </FluidImageGridBannerImageContainer>
                    <FluidImageGridBannerImageContainer padding='15px 0 0 0'>
                        <FluidImageBannerSquareImage src={images[1].url} alt={images[1].description} />
                    </FluidImageGridBannerImageContainer>
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer padding='0 15px 0 15px'>
                    <FluidImageBannerVerticalImage src={images[2].url} alt={images[2].description} />
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer alignment='vertical' padding='0 15px 0 15px'>
                    <FluidImageGridBannerImageContainer alignment='horizontal' padding='0 0 15px 0'>
                        <FluidImageGridBannerImageContainer padding='0 15px 0 0'>
                            <FluidImageBannerSquareImage src={images[3].url} alt={images[3].description} />
                        </FluidImageGridBannerImageContainer>
                        <FluidImageGridBannerImageContainer padding='0 0 0 15px'>
                            <FluidImageBannerSquareImage src={images[4].url} alt={images[4].description} />
                        </FluidImageGridBannerImageContainer>
                    </FluidImageGridBannerImageContainer>
                    <FluidImageGridBannerImageContainer padding='15px 0 0 0'>
                        <FluidImageBannerHorizontalImage src={images[5].url} alt={images[5].description} />
                    </FluidImageGridBannerImageContainer>
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer padding='0 0 0 15px'>
                    <FluidImageBannerVerticalImage src={images[6].url} alt={images[6].description} />
                </FluidImageGridBannerImageContainer>
            </div>
        )
    }
    renderTabletLayout = (images: any) => {
        return (
            <div>
                <FluidImageGridBannerImageContainer padding='0 15px 0 15px'>
                    <FluidImageBannerVerticalImage src={images[2].url} alt={images[2].description} />
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer alignment='vertical' padding='0 15px 0 15px'>
                    <FluidImageGridBannerImageContainer alignment='horizontal' padding='0 0 15px 0'>
                        <FluidImageGridBannerImageContainer padding='0 15px 0 0'>
                            <FluidImageBannerSquareImage src={images[3].url} alt={images[3].description} />
                        </FluidImageGridBannerImageContainer>
                        <FluidImageGridBannerImageContainer padding='0 0 0 15px'>
                            <FluidImageBannerSquareImage src={images[4].url} alt={images[4].description} />
                        </FluidImageGridBannerImageContainer>
                    </FluidImageGridBannerImageContainer>
                    <FluidImageGridBannerImageContainer padding='15px 0 0 0'>
                        <FluidImageBannerHorizontalImage src={images[5].url} alt={images[5].description} />
                    </FluidImageGridBannerImageContainer>
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer padding='0 0 0 15px'>
                    <FluidImageBannerVerticalImage src={images[6].url} alt={images[6].description} />
                </FluidImageGridBannerImageContainer>
            </div>
        )
    }
    renderMobileLayout = (images: any) => {
        return (
            <FluidImageGridBannerImageContainer alignment='vertical'>
                <FluidImageGridBannerImageContainer alignment='horizontal'>
                    <FluidImageGridBannerImageContainer alignment='vertical' padding='0 10px 10px 20px'>
                        <FluidImageGridBannerImageContainer padding='0 0 10px 0'>
                            <FluidImageBannerSquareImage src={images[0].url} alt={images[0].description} />
                        </FluidImageGridBannerImageContainer>
                        <FluidImageGridBannerImageContainer padding='10px 0 0 0'>
                            <FluidImageBannerSquareImage src={images[1].url} alt={images[1].description} />
                        </FluidImageGridBannerImageContainer>
                    </FluidImageGridBannerImageContainer>
                    <FluidImageGridBannerImageContainer padding='0 20px 10px 10px'>
                        <FluidImageBannerVerticalImage src={images[2].url} alt={images[2].description} />
                    </FluidImageGridBannerImageContainer>
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer padding='10px 20px 10px 20px'>
                    <FluidImageBannerHorizontalImage src={images[5].url} alt={images[5].description} />
                </FluidImageGridBannerImageContainer>
                <FluidImageGridBannerImageContainer alignment='horizontal' padding='10px 20px 0 20px'>
                    <FluidImageGridBannerImageContainer padding='0 10px 0 0'>
                        <FluidImageBannerSquareImage src={images[3].url} alt={images[3].description} />
                    </FluidImageGridBannerImageContainer>
                    <FluidImageGridBannerImageContainer padding='0 0 0 10px'>
                        <FluidImageBannerSquareImage src={images[4].url} alt={images[4].description} />
                    </FluidImageGridBannerImageContainer>
                </FluidImageGridBannerImageContainer>
            </FluidImageGridBannerImageContainer>
        )
    }

    render() {
        const images = this.props.images;
        return (
            <div>
                <Responsive as={FluidImageGridBannerContainer} minWidth={theme.responsive.desktop.minWidth}>
                    {
                        this.renderDesktopLayout(images)
                    }
                </Responsive>
                <Responsive as={FluidImageGridBannerContainer} maxWidth={theme.responsive.tablet.maxWidth} minWidth={theme.responsive.tablet.minWidth}>
                    {
                        this.renderTabletLayout(images)
                    }
                </Responsive>
                <Responsive as={FluidImageGridBannerContainerMobile} maxWidth={theme.responsive.mobile.maxWidth}>
                    {
                        this.renderMobileLayout(images)
                    }
                </Responsive>
            </div>

        )
    }
}

export default FluidImageGridBanner;